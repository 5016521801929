<template>
   <!-- <div class="ms_register_popup"> -->
   <!----Login Popup Start---->
        <!-- <div class="modal  centered-modal" role="dialog"> -->
            <div class="modal-dialog login_dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="ms_register_img" style="margin-top: 5vh;">
                            <img src="../../assets/zain_gray.png" alt="" class="img-fluid" />
                        </div>
                        <div class="ms_register_form">
                            <h2>تسجيل الدخول</h2>
                            <form  @submit.prevent="onLogin()">
                            <div class="form-group">
                                <input type="text" v-model="msisdn" placeholder="رقم الهاتف" class="form-control">
                                <span class="form_icon">
							       <i class="fa_icon form-user" aria-hidden="true"></i>
						        </span>
                            </div>
                            <!-- <a href="profile.html" class="ms_btn" target="_blank">تسجيل الدخول</a> -->
                            <a @click="onLogin()" class="ms_btn pointer">
                                <span v-if="loading" class="px-1">جاري تسجيل الدخول</span> &nbsp;
                                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                <span v-else class="">تسجيل الدخول</span>
                            </a>
                            <p>ليس لديك اشتراك? <a @click="Subscrib()" data-toggle="modal" class="ms_modal1 hideCurrentModel">اشتراك الان</a></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </div> -->
   <!-- </div> -->
</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import { useToast } from "vue-toastification";
export default {
   name: 'AppLogin',
   data() {
      return {
         msisdn: "",
         loading: false,
      };
   },
    setup() {
        const Subscrib = () => {
            window.open("https://dsplp.sd.zain.com/?p=6245427249", "_self");
        }
        return { Subscrib }
    },
   methods: {
      async onLogin() {
         const toast = useToast();
         //  let input = document.querySelector(".input");
         //  let button = document.querySelector(".button");
         //  button.disabled = true;
         //  input.addEventListener("change", stateHandle);

         //  function stateHandle() {
         //    if(document.querySelector(".input").value === "") {
         //       button.disabled = true;
         //    } else {
         //       button.disabled = false;
         //    }
         //  }
          if (this.msisdn != "") {
               var phoneno = (/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/);
               if (this.msisdn.match(phoneno)) {
                  if (this.msisdn.startsWith("09")) {
                     let phone = this.msisdn.substr(1);
                     this.loading = true
                     await HTTPDSP.post("DSPCheckLogin.php?msisdn=249"+phone).then((res) => {
                            if (res.data.status == 1 && res.data.remming_minutes > 0) {
                                this.$cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('msisdn', res.data.msisdn, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes,})
                                toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", { timeout: 2000 });
                                setTimeout(() => this.$router.push({ path: "/" }), 2500);
                            } else if (res.data.status == 1 && res.data.remming_minutes < 0) {
                                toast.success("تم تسجيل الدخول", { timeout: 2500, });
                                this.$cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('msisdn', res.data.msisdn, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes,})
                                toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", { timeout: 2000 });
                            } else if (res.data.status == 0) {
                                toast.warning("عفوا انت لست مشترك في هذة المنصة", { timeout: 2000, });
                                setTimeout(() => { window.open("https://dsplp.sd.zain.com/?p="+res.data.product_code); }, 2500);
                              
                            }else {
                                toast.info("عفوا انت لست مشترك في منصة " , { timeout: 2000 });
                                setTimeout(() => { window.open("https://dsplp.sd.zain.com/?p="+res.data.product_code); }, 2500);
                              //   window.open("http://test.zaindsp.com:3033/?p="+res.data.product_code);	
                            }
                        }).catch((error) => {
                           console.log(error);
                        }).finally(() => {
                           this.loading = false;
                        });
                  } else {
                     toast.info("رقم الهاتف ليس زين" , { timeout: 2000 });
                  }
               } else {
                  toast.info("رقم الهاتف يجب ان يتكون من 10 رقم ويبدا بي 09 " , { timeout: 2000 });
               }
            } else {
               toast.info("الرجال ادخال رقم الهاتف" , { timeout: 2000 });
            }
      }
    },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>